/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 *
 * jquery is imported from wordpress
 * popper, bootstrap, select2
 */
try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery;

	require('bootstrap');
	require('select2/dist/js/select2.full');
} catch (e) {}
