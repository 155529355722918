import './products';
import './suppliers';
import './eventWall';
import $ from 'jquery';

/**
 * AJAX Config, comes from functions.php using wp_localize_script
 */
window.SD_AJAX = SDAjax;
// Header language switcher
jQuery('.page-header .qtranxs-lang-menu').on('click', function(e) {
	var $this = $(this);
	e.preventDefault();
	$this
		.find('.sub-menu')
		.toggleClass('langpick-open')
		.slideToggle(200);
});
// Header language switcher - click outside
jQuery(document).on('click', function(e) {
	if ($(e.target).closest('.page-header .qtranxs-lang-menu').length === 0) {
		jQuery('.page-header .qtranxs-lang-menu')
			.find('.sub-menu')
			.slideUp(200)
			.removeClass('langpick-open');
	}
});
// Language switcher - stop event propagation
jQuery('.qtranxs-lang-menu .qtranxs-lang-menu-item').on('click', function(e) {
	e.stopPropagation();
});

if (jQuery('.home_slider').length) {
	jQuery('.home_slider').each(function() {
		const $this = jQuery(this);
		$this.slick({
			slidesToScroll: 1,
			slidesToShow: 1,
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1000,
			fade: true,
			customPaging: function(slick, index) {
				return '<span class="home_slider__pagination_item"></span>';
			},
		});
	});
}

if (jQuery('.home_our-products__slider').length) {
	jQuery('.home_our-products__slider').each(function() {
		const $this = jQuery(this);
		$this.slick({
			slidesToScroll: 1,
			slidesToShow: 4,
			arrows: true,
			dots: false,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 1000,
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 4,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					},
				},
			],

			prevArrow: $this
				.closest('.home__our-products')
				.find('.home_our-products__slider_arrows .arrow-prev')
				.first(),
			nextArrow: $this
				.closest('.home__our-products')
				.find('.home_our-products__slider_arrows .arrow-next')
				.first(),
		});
	});
}

// events move export box with js

if (jQuery('.mec-event-export-module.mec-frontbox').length) {
	const $items = jQuery('.mec-event-export-module.mec-frontbox');

	$items.each(function() {
		const $item = jQuery(this);
		const $wrap = $item.closest('.mec-single-event');

		$wrap.find('.mec-event-social.mec-frontbox').after($item.clone());
		$item.remove();
	});
}

jQuery(document).on(
	'click',
	'.page-footer__main .common-menu__ul .menu-item-has-children > .common-menu__a, .sidebar-menu .common-menu__ul .menu-item-has-children > .common-menu__a',
	function(e) {
		e.preventDefault();
		const $this = $(this);
		$this.parent().toggleClass('submenu-open');
		$this
			.parent()
			.find('.sub-menu')
			.slideToggle(300);
	},
);

if (jQuery('.about-us-pg__partners_slider').length > 0) {
	jQuery('.about-us-pg__partners_slider').each(function() {
		const $this = $(this);

		$this.slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows: true,
			dots: false,
			autoplay: true,
			autoplaySpeed: 3000,
			speed: 1000,

			prevArrow: $this
				.closest('.about-us-pg__partners')
				.find('.about-us-pg__partners_slider_arrows .arrow-prev')
				.first(),
			nextArrow: $this
				.closest('.about-us-pg__partners')
				.find('.about-us-pg__partners_slider_arrows .arrow-next')
				.first(),
		});
	});
}

const MembersFilters = {
	/**
	 * Initialize DOM Listeners
	 */
	init: function() {
		const self = this;


		/**
		 * When changing filter type between 'fruit suppliers' and 'products and services'
		 */
		jQuery(document).on('change', '#members_filter_type', function(e) {
			const $this = jQuery(this);

			if ($this.val() === 'products') {
				self.loadProductsFilter();
			} else if ($this.val() === 'services') {
				self.loadServicesFilter();
			}
		});

		jQuery('#members_filter_products').trigger('change');


		/**
		 * Change parent category - load subcategories
		 */
		// jQuery(document).on('change', '#members_filter_parent_products', function() {
		// 	const $this = $(this);
		// 	const $val = $this.val();
		//
		// 	const $row = jQuery('#members_filters_row');
		//
		//
		// 	$('#member_filter_product_ids_col').remove();
		//
		//
		// 	if ($row.data('product-ids-enabled') === 'y') {
		// 		self.beginLoading();
		//
		// 		if ($val !== undefined && $val !== null) {
		// 			// this should fire when product select is changed
		// 			jQuery.ajax({
		// 				url: window.SD_AJAX.ajax_url,
		// 				type: 'POST',
		// 				data: {
		// 					action: 'load_child_categories',
		// 					product_cat: $val,
		// 				},
		// 				success: function(raw) {
		// 					const response = JSON.parse(raw);
		// 					console.log('WHAT THJE FUCK');
		// 					console.log(response);
		// 					self.stopLoading();
		// 					self.addSubcategoriesSelect(response);
		// 				},
		// 				error: function(error) {
		// 					self.stopLoading();
		// 					console.log(error);
		// 				},
		// 			});
		// 		} else {
		// 			jQuery('#member_filter_product_cat_col').remove();
		// 		}
		// 	}
		// });


		/**
		 * Load child products on category select change
		 * @todo: Very buggy.
		 */
		jQuery(document).on('change', '#members_filter_product_cat', function() {
			const $this = $(this);
			const $val = $this.val();

			const $row = jQuery('#members_filters_row');

			self.beginLoading();

			if ($val !== undefined && $val !== null) {
				// this should fire when product select is changed
				jQuery.ajax({
					url: window.SD_AJAX.ajax_url,
					type: 'POST',
					data: {
						action: 'load_products',
						product_cat: $val,
					},
					success: function(raw) {
						const response = JSON.parse(raw);
						self.stopLoading();
						self.addProductDetailedSelect(response);
					},
					error: function(error) {
						self.stopLoading();
						console.log(error);
					},
				});
			} else {
				jQuery('#member_filter_product_ids_col').remove();
			}
		});


		/**
		 * On change of services dropdown - act accordingly
		 */

		jQuery(document).on('change', '#members_filter_other_cat', function(e){
			const $this = jQuery(this);
			const _val = $this.val();

			if(_val === 'toggle_services') {
				// shoot request for loading service categories\

				self.loadServiceCategories();


			} else {
				// if is not needed to toggle services, load products for selected category
				jQuery('#member_filter_services_cat_col, #member_filter_services_col').hide();

				jQuery.ajax({
					url: window.SD_AJAX.ajax_url,
					type: 'POST',
					data: {
						action: 'load_child_categories',
						product_cat: _val,
					},
					success: function(raw) {
						const response = JSON.parse(raw);
						self.stopLoading();
						console.log(response);
						// self.addProductDetailedSelect(response);
						self.addServiceProductsSelect(response);
					},
					error: function(error) {
						self.stopLoading();
						console.log(error);
					},
				});
			}

		});
		
		/**
		 * On service category change - load services from that category
		 */
		jQuery(document).on('change', '#members_filter_services_category', function(e){


			const $this = $(this);
			const $val = $this.val();

			const $row = jQuery('#members_filters_row');

			self.beginLoading();

			if ($val !== undefined && $val !== null) {
				// this should fire when product select is changed
				jQuery.ajax({
					url: window.SD_AJAX.ajax_url,
					type: 'POST',
					data: {
						action: 'load_services_from_category',
						member_service_cat: $val,
					},
					success: function(raw) {
						const response = JSON.parse(raw);
						console.log(response);
						self.stopLoading();
						self.addServicesSelect(response);
					},
					error: function(error) {
						self.stopLoading();
						console.log(error);
					},
				});
			} else {
				// jQuery('#member_filter_product_cat_col').remove();
			}
		});

		/**
		 * Apply filters on load document
		 */
		jQuery(document).ready(function(e) {
			if (window.location.search.length > 0) {
				jQuery("#members_filter_apply").trigger('click');
			}
		});
		
		/**
		 * Display select of products
		 */
		jQuery(document).ready(function(e) {
			if (jQuery('#members_filter_type').val() === 'products') {
				jQuery('#members_filter_product_category_col').css('display', 'block');
			} else {
				jQuery('#members_filter_product_category_col').css('display', 'none');
			}
		});
		
		jQuery(document).on('change', '#members_filter_type', function(e) {
			if (jQuery('#members_filter_type').val() === 'products') {
				jQuery('#members_filter_product_category_col').css('display', 'block');
			} else {
				jQuery('#members_filter_product_category_col').css('display', 'none');
			}
		});
		
		/**
		 * Apply filters
		 */
		jQuery(document).on('click', '#members_filter_apply', function(e) {
			e.preventDefault();

			const $this = jQuery(this);

			const request = {
				type: $('#members_filter_type').val(),
			};

			const originalText = $this.text();
			const loadingText = $this.data('loading-text');

			if (request.type === 'products') {
				request.product_cat = $('#members_filter_product_cat').val();

				// include product IDS.
				if(jQuery('#members_filter_product_ids_col').length && !jQuery('#members_filter_product_ids_col').first().hasClass('not-available')) {
					if (jQuery('#members_filter_product_ids').length) {
						request.product_ids = jQuery('#members_filter_product_ids').val();
					}
				}

				// Include certificates
				if (jQuery('#members_filter_certificates').length) {
					request.certificates = jQuery('#members_filter_certificates').val();
				}


			} else if (request.type === 'services') {
				request.has_fridge = $('#member_filter_fridge_col')
					.find('input')
					.is(':checked');
				request.services = $('#members_filter_services').val();

				if(jQuery('#member_filter_service_products_col').length && !jQuery('#member_filter_service_products_col').first().hasClass('not-available')) {
					if (jQuery('#members_filter_product_item').length) {
						request.product_ids = jQuery('#members_filter_product_item').val();
					}
				}

				if(jQuery('#members_filter_other_cat_col').length) {
					request.product_cat = $('#members_filter_other_cat').val();
				}

				// override if precise category was selected

				if(jQuery('#members_filter_product_category_precise').length) {
					var preciseCat = jQuery('#members_filter_product_category_precise').val();

					console.log('present');
					console.log(preciseCat);

					if(preciseCat !== undefined && preciseCat !== null && preciseCat !== '') {
						request.product_cat = preciseCat;
					}
				}
			}



			request.action = 'load_members';

			self.beginLoading();
			// self.replaceUrl(request);

			$this.text(loadingText);

			jQuery.ajax({
				url: window.SD_AJAX.ajax_url,
				type: 'POST',
				data: request,
				success: function(response) {
					self.stopLoading();
					self.renderResults(response);
					$this.text(originalText);
				},
				error: function(error) {
					self.stopLoading();
					console.log(error);
					$this.text(originalText);
				},
			});
		});
	},
	
	

	/**
	 * Show products related filters basically
	 */
	loadProductsFilter: function() {
		this.showProductsMainSelect();
	},

	/**
	 * Show service related filters basically
	 */
	loadServicesFilter: function() {
		this.showServicesMainSelect();
	},

	/**
	 * sHOW PRODUCTRS MAIN SELECT
	 */
	showProductsMainSelect: function() {
		jQuery('.is-product-filter').addClass('shown');
		jQuery('.is-service-filter').removeClass('shown');
		this.reloadSelect2(jQuery('.is-product-filter').find('.select2-cp'));
		this.reloadSelect2(jQuery('#members_filter_product_category_col, #members_filter_product_ids_col').find('.select2-cp'));

		console.log('is sadoij<shdafsafsabfsa');
		console.log(jQuery('#members_filter_product_category_col, #members_filter_product_ids_col').find('.select2-cp'));
	},


	loadServiceCategories: function()
	{
		var self = this;


		jQuery('#member_filter_service_products_col').hide().addClass('not-available');

		jQuery('#member_filter_services_cat_col').show();
		self.reloadSelect2(jQuery('#member_filter_services_cat_col').find('.select2-cp'));
	},


	loadServiceProducts()
	{

	},



	/**
	 * Show services main select
	 */
	showServicesMainSelect: function() {
		jQuery('.is-product-filter').removeClass('shown');
		jQuery('.is-service-filter').addClass('shown');

		$('#members_filter_other_cat_col').show();

		// $('#members_filter_other_cat_col')
		$('#members_filter_product_ids_col').hide().addClass('not-available');

		this.reloadSelect2(jQuery('.is-service-filter').find('.select2-cp'));
	},

	/**
	 * Add subcategories
	 * @param response
	 */
	addProductDetailedSelect(response) {
		if (response.status && response.render !== undefined) {
			const render = response.render;
			const $selectBox = jQuery('#members_filter_product_ids_col').find('.select2-cp');
			$selectBox.html($(render).find('option'));

			$('#members_filter_product_ids_col').show().removeClass('not-available');
			this.reloadSelect2(jQuery('#members_filter_product_category_col, #members_filter_product_ids_col').find('.select2-cp'));

		} else {
			// if no response
			$('#members_filter_product_ids_col').hide().addClass('not-available');
		}
	},


	addServiceProductsSelect(response) {
		if (response.status && response.render !== undefined) {
			const render = response.render;

			jQuery('#member_filter_service_products_col').find('.select-placeholder').html(response.render);

			jQuery('#member_filter_service_products_col').show().removeClass('not-available');

			this.reloadSelect2(jQuery('#member_filter_service_products_col').find('.select2-cp'));

		} else {
			$('#members_filter_other_cat').hide().addClass('not-available');
		}
	},

	/**
	 *
	 * @param response
	 */
	addServicesSelect(response)
	{
		var self = this;

		if (response.status && response.render !== undefined) {
			const render = response.render;
			const $selectBox = jQuery('#member_filter_services_col').find('.select2-cp');
			$selectBox.html($(render).find('option'));

			jQuery('#member_filter_services_col').show();

			self.reloadSelect2(jQuery('#member_filter_services_col').find('.select2-cp'));
		}
	},


	/**
	 *
	 * @param response
	 */
	addServicesMainSelect(response)
	{
		// if (response.status && response.render !== undefined) {
		// 	const render = response.render;
		// 	const $selectBox = jQuery('#member_filter_services_col').find('.select2-cp');
		// 	$selectBox.html($(render).find('option'));
		// }
	},

	/**
	 * Add subcategories select
	 * @param response
	 */
	addSubcategoriesSelect(response)
	{
		if (response.status && response.render !== undefined) {
			const render = response.render;
			let template = `
                <div class="col-12 col-md-3 suppliers-pg__filters_item is-product-filter shown" id="member_filter_product_subcat_col">
                    ${render}
                </div>
            `;

			if (jQuery('#member_filter_product_subcat_col').length === 0) {
				jQuery('#members_filter_product_cat_col').after(template);
				console.log('a intrat pidaru aici');
				this.reloadSelect2(jQuery('#member_filter_product_subcat_col').find('.select2-cp'));
			} else {
				const $selectBox = jQuery('#member_filter_product_subcat_col').find('.select2-cp');
				$selectBox.html($(render).find('option'));
			}
		}
	},

	renderResults: function(response) {
		console.log('results');
		console.log(response.render);
		jQuery('#supplier-dyna-wrapper').html(response);
	},

	/**
	 * Object to url params
	 * @param data
	 * @return {string}
	 */
	objectToUrlParams: function(data) {
		let value = '';
		if (data && typeof data === 'object') {
			Object.entries(data).forEach((itm, index, arr) => {
				const [key, val] = itm;
				const valueExist = val !== null && val !== undefined && val !== '';

				if (index === 0) value = '?';
				if (valueExist && Array.isArray(val) && val.length) value += `${key}=${encodeURI(JSON.stringify(val))}`;
				else if (valueExist && !Array.isArray(val)) value += `${key}=${encodeURI(val)}`;
				if (arr.length - 1 !== index && valueExist) value += '&';
			});
		}
		if (value === '?&') value = '';
		return value;
	},

	/**
	 * replace URL
	 * @param data
	 */
	replaceUrl: function(data) {
		const originUrl = $('#supplier-dyna-wrapper').attr('data-url');
		const uriEncodedData = this.objectToUrlParams(data);
		window.history.replaceState(null, null, `${originUrl}${uriEncodedData}`);
	},

	beginLoading: function() {
		jQuery('.suppliers-pg__content').addClass('loading');
	},

	stopLoading: function() {
		jQuery('.suppliers-pg__content').removeClass('loading');
	},

	/**
	 * Reload select2
	 * @param $items
	 */
	reloadSelect2: function($items) {
		$items.each(function() {
			const $this = $(this);
			let placeholder = $this.data('placeholder');

			if (placeholder === '') {
				placeholder = 'Filter';
			}

			$this.select2({
				placeholder: placeholder,
				minimumResultsForSearch: -1,
			});

			jQuery('.select2-container, .select2-container .select2-search__field').css('width', '100%');
		});
	},
};

jQuery(document).ready(function() {
	MembersFilters.init();
});
jQuery(function($) {
	// use jQuery code inside this to avoid "$ is not defined" error
	$('#news_load_more').click(function() {
		var button = $(this),
			_page = parseInt(button.data('page') + 1),
			data = {
				action: 'loadmore',
				query: window.SD_AJAX.posts, // that's how we get params from wp_localize_script() function
				page: _page,
			};
		var maxPages = jQuery('#blog-posts-wrapper').data('max-pages');

		if (parseInt(_page) <= parseInt(maxPages)) {
			$.ajax({
				// you can also use $.post here
				url: window.SD_AJAX.ajax_url, // AJAX handler
				data: data,
				type: 'POST',
				success: function(data) {
					button.data('page', parseInt(_page));
					if (data) {
						jQuery('#blog-posts-wrapper').append(data); // insert new posts

						// you can also fire the "post-load" event here if you use a plugin that requires it
						// $( document.body ).trigger( 'post-load' );
					}
					if (parseInt(_page) >= parseInt(maxPages)) {
						button.remove();
					}
				},
			});
		}
	});
});
