import $ from 'jquery';

/**
 * Custom-file from bootstrap framework.
 * Show file name.
 */
(function() {
	$('.custom-file-input').on('change', function() {
		const files = $(this).get()[0].files;
		const placeholder = $(this).next('.custom-file-label');

		if (files.length) placeholder.html(files[0].name);
		else {
			$(this).val('');
			placeholder.html('Choose file');
		}
	});
})();
