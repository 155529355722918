import $ from 'jquery';

(function() {
	if ($('#products').length) {
		jQuery('.supplier_filter__select2').each(function(){
			const $this = $(this);
			let placeholder = $this.data('placeholder');

			if(placeholder === '') {
				placeholder = 'Filter';
			}

			$this.select2({
				placeholder: placeholder,
				minimumResultsForSearch: -1,
			});
		});

		// Sticky filters
		$(document).ready(function() {
			const filtersSelector = '.products-pg__filters';
			const filtersTop = $(filtersSelector).offset().top;

			const stickyFilters = () => {
				const scrollTop = $(window).scrollTop();
				const headerHeight = $('.page-header')[0].getBoundingClientRect().height;
				const wpAdminHeight = $('#wpadminbar').length ? $('#wpadminbar')[0].getBoundingClientRect().height : 0;
				if (scrollTop + headerHeight > filtersTop) {
					$(filtersSelector).addClass('sticky');
					$(filtersSelector).css('top', `${headerHeight + wpAdminHeight}px`);
				} else {
					$(filtersSelector).removeClass('sticky');
				}
			};
			stickyFilters();

			$(window).scroll(function() {
				stickyFilters();
			});
		});
	}
})();
