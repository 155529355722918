import $ from 'jquery';

/**
 * Added class modifier to page header for fixing wp-admin-bar view position
 */
(function() {
	$(document).ready(function() {
		if ($('#wpadminbar').length) {
			$('.page-header, .products-pg__filters, .suppliers-pg__filters').addClass('admin');
		} else {
			$('.page-header, .products-pg__filters, .suppliers-pg__filters').removeClass('admin');
		}
	});
})();
