import $ from 'jquery';

/**
 * Sidebar menu
 */
(function() {
	$(document).ready(function() {
		$('#sidebar-open').click(function() {
			$('#sidebar-menu').addClass('open');
			$('body').addClass('blocked');
		});
		$('#sidebar-close').click(function() {
			$('#sidebar-menu').removeClass('open');
			$('body').removeClass('blocked');
		});

		// Admin bar fix
		if ($('#wpadminbar').length) {
			const adminBarTop = $('#wpadminbar').offset().top;

			const stickyNav = () => {
				const scrollTop = $(window).scrollTop();
				if (scrollTop > adminBarTop) {
					$('#sidebar-menu').removeClass('admin');
				} else {
					$('#sidebar-menu').addClass('admin');
				}
			};

			stickyNav();

			$(window).scroll(function() {
				stickyNav();
			});
		}
	});
})();
