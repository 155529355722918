import $ from 'jquery';

(function() {
	$(document).ready(function() {
		if ($('#event-wall').length) {
			const timer = '.event-count-down-timer';
			const title = '.event-wall__h1';

			/**
			 * Countdown timer for event page
			 * Source: https://www.w3schools.com/howto/howto_js_countdown.asp
			 */
			if ($(timer).length) {
				$(timer).each(function() {
					// Read date from data attribute
					const date =  $(this).attr('data-date') || null;

					// Set the date we're counting down to
					const countDownDate = new Date(date).getTime();

					// Timer template
					const getTimerView = (days, hours, minutes, seconds) => `
					<div>
						<span class="event-wall__timer_col">
							<div class="event-wall__timer_number font-weight-bold">${days}</div>
							<div class="event-wall__timer_label font-weight-bold">Days</div>
						</span>
						<span class="event-wall__timer_col">
              <div class="event-wall__timer_number">${hours}</div>
              <div class="event-wall__timer_label">Hours</div>
            </span>
            <div class="event-wall__timer_col">
              <div class="event-wall__timer_number">${minutes}</div>
              <div class="event-wall__timer_label">Minutes</div>
            </div>
            <div class="event-wall__timer_col">
              <div class="event-wall__timer_number">${seconds}</div>
              <div class="event-wall__timer_label">Seconds</div>
            </div>
					</div>
				`;

					// Update the count down every 1 second
					const x = setInterval(() => {
						// Get today's date and time
						const now = new Date().getTime();

						// Find the distance between now and the count down date
						const distance = countDownDate - now;

						// Time calculations for days, hours, minutes and seconds
						const days = Math.floor(distance / (1000 * 60 * 60 * 24));
						const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
						const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
						const seconds = Math.floor((distance % (1000 * 60)) / 1000);

						// Show timer
						$(this).html(getTimerView(days, hours, minutes, seconds));

						// If the count down is finished, write some text
						if (distance < 0) {
							clearInterval(x);
							$(this).html('EXPIRED');
						}
					}, 1000);
				});
			}

			/**
			 * Reduce font-size of title in case if some words going outside of container
			 */
			if ($(title).length) {
				const text = $('.event-wall__h1').text();
				const splittedText = text.split(' ');
				console.warn(splittedText);
				const wordLengthIsMoreThan = (number) => splittedText.some(itm => itm.length >= number);

				if (wordLengthIsMoreThan(6)) $(title).addClass('word-broke-on-length-6');
				if (wordLengthIsMoreThan(8)) $(title).addClass('word-broke-on-length-8');
				if (wordLengthIsMoreThan(10)) $(title).addClass('word-broke-on-length-10');
			}
		}
	});
})();
