import $ from 'jquery';

/**
 * jQuery sticky header
 * Source: https://codepen.io/_codemics/pen/PwEbYJ?editors=0010
 */
(function() {
	$(document).ready(function() {
		/**
		 * grab the initial top offset of the navigation
		 */
		const stickyNavTop = $('.page-header').offset().top;

		/**
		 * our function that decides weather the navigation bar
		 * should have "fixed" css position or not.
		 */
		const stickyNav = () => {
			/**
			 * our current vertical position from the top
			 */
			const scrollTop = $(window).scrollTop();

			/**
			 * if we've scrolled more than the navigation,
			 * change its position to fixed to stick to top,
			 * otherwise change it back to relative
			 */
			if (scrollTop > stickyNavTop) {
				$('.page-header').addClass('sticky');
			} else {
				$('.page-header').removeClass('sticky');
			}
		};

		stickyNav();

		/**
		 * and run it again every time you scroll
		 */
		$(window).scroll(function() {
			stickyNav();
		});
	});
})();
